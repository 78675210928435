.container-main
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: baseline  

.update-selected
  display: flex
  padding-left: 20px
  font-weight: bold
  color: #9b9b9b
  font-size: 12px
  align-items: center
  width: 400px
  justify-content: space-between
  margin-bottom: 20px 

  label
    margin-right: 10px

.update-item
  flex-grow: 2

.buttons
  display: flex
  button  
    margin-right: 20px
    margin-bottom: 20px