.watched-item
    position: relative
    cursor: pointer
    opacity: .5

.isWatched
    opacity: 1
    &::after
        color: green
        content: '✔'
        position: absolute
        right: -7px
        font-size: 10px
        top: -6px
        font-weight: bold