.blast-view
  background-color: #fff
  text-align: left
  padding: 20px

.form-crud-actions
  padding: 3em 0 0
  text-align: right 

.optionListContainer
  padding-left: 0px !important

// FORMS
.form-wrap

  .form-group
    padding-bottom: 1.5em

    input
      display: block
      width: 100%
      padding: .5em

  .form-field
    padding: 0 0 1.5em

  .col-form-label
    display: block

    b
      font-weight: 400
      font-size: 14px
  
  .form-control
    padding: .7em 1em
    border-radius: 5px 
    display: block
    width: 100%
    box-sizing: border-box
    border: 1px solid rgb(226, 229, 237)
    font-size: 14px 

    &:active,
    &:focus
      border: 1px solid #39DECB

  .formError
    font-size: 12px
    text-align: right
    color: red

  .form-crud-actions
    text-align: right

textarea
  font-size: 16px
  color: #000

.control-feedback
  color: #c50000
  text-align: right
  font-size: 14px

button,
.btn
  box-shadow: none!important
  color: #fff
  font-weight: bold
  font-size: 16px
  border: 0
  outline: none
  border-radius: 5px
  padding: .7em 3em
  transition: all .3s
  cursor: pointer

  &:disabled
    opacity: .5
    cursor: not-allowed

  &.btn-primary
    background-color: #a646eb

    &:hover
      background-color: rgb(116, 49, 164)

  &.btn-secondary
    background-color: #39DECB

  &.btn-warning
    background-color: #ffcf4d
    color: #2a1d3b

    &:hover
      background-color: #deb74c

  &.btn-danger,
  &.btn-error
    background-color: #f67173

    &:hover
      background-color: #e15c5e
    
  
  &.btn-warning
    background-color: #ffcf4d
    

  &.btn-warning,
  &.btn-danger,
  &.btn-error
    color: #2a1d3b

  &.btn-block
    width: 100%