.tendero-view 
  background-color: #fff   
  text-align: left
  padding: 20px
  overflow-wrap: anywhere


.tendero-view input.short 
  width: 173px
  margin-right: 2px


.lat-label 
  justify-content: left !important
  width: 173px


.long-label 
  justify-content: left !important
  width: 173px


.lat-field   
  margin-bottom: 20px


.long-field   
  margin-bottom: 20px


.tendero-name 
  padding-top: 14px


.tendero-title 
  color: rgb(97, 97, 97)
  font-size: 16px
  font-weight: bold
  margin: 10px 0 8px 0


.tendero-view .btn-danger 
  color: #fff

.delete-section

  .delete-description
    margin-bottom: 30px
  .delete-buttons
    text-align: right
  .btn-button
    margin-left: 10px
.available-points__container
  display: flex
  gap: 40px
  justify-content: center
  align-items: center 
  margin-bottom: 30px

.available-points
  border-width: 0px
  width: 166px
  height: 54px
  background: inherit
  background-color: rgba(242, 242, 242, 1)
  border: none
  border-radius: 6px
  -moz-box-shadow: none
  -webkit-box-shadow: none
  box-shadow: none
  text-align: center
  // margin: auto
  padding: 10px
  display: flex
  flex-direction: column

  .available-points__quantity
    font-size: 20px
    font-weight: 600
  .available-points__text
    font-size: 16px
    color: #7F7F7F

.add-points
  margin: 10px 0px
  span
    font-size: 16px
    color: #7F7F7F


.form-points-container
  display: flex
  justify-content: space-between
  align-items: center

  .form-points__motive
    width: 470px !important
    margin-right: 10px
    input
      border-radius: none !important 
  
  .form-points__quantity
    width: 130px !important
    margin-right: 10px
    input
      border-radius: none !important 


.header-points
  display: flex
  align-items: center
  margin: 10px 0px


.table-points
  display: flex
  flex-flow: column
  // height: 650px
  // margin-bottom: 200px
  section
    margin: 0 !important

.tabContainer
  background:  rgb(242, 242, 242)
  padding: 10px 5px
  margin-top: 20px 
  margin-bottom: 200px
  

.tabContainer__section
  background: #fff
  border-radius: 0px 0px 10px 10px
  padding: 0 10px
