@import './utils/_mixins.scss'
@import './theme.sass'


html
  -ms-text-size-adjust: 100%
  -webkit-text-size-adjust: 100%

html, body
  height: 100%
  width: 100%

body
  margin: 0

  
button
  -webkit-tap-highlight-color: rgba(0,0,0,0)
  &:focus
    outline: 0

*
  &:focus
    outline: 0

#root
  height: 100%

.react-swipeable-view-container
  height: 100%

@media screen and (min-width: 980px)
  .MuiContainer-maxWidthMd
    max-width: 980px !important

#item-menu,
#product-menu
  .MuiPaper-root
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35) !important
    ul > li
      font-family: FuturaBT
      font-size: 15px
      font-weight: normal
      font-stretch: normal
      font-style: normal
      line-height: 1.4
      letter-spacing: 0.37px
      color: #2f2f2f

.title
  font-size: 23px
  font-weight: 500
  line-height: 1.18
  text-align: center
  color: #2f2f2f
.subtitle
  font-size: 15px
  line-height: 1.27
  letter-spacing: 0.17px
  text-align: center
  color: #585858

.error
  color: #F44336
  font-size: 12px

.MuiContainer-root
  padding: 0 !important

.container_with_drawer
  margin-left: 299px
  @media screen and (min-width: 600px)
    margin-left: 420px


// General Clases  
.align-center
  text-align: center

.react-datepicker-popper 
  z-index: 3

.react-tagsinput-input
  width: auto !important
.react-tagsinput-tag
  background-color: #E9D0FB !important
  color: #A646EB !important
  border: 1px solid #A646EB !important
.react-tagsinput--focused
  border-color: #A646EB !important