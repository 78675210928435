// Generel Styles for WAO APP
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;700&display=swap')

body
  font-family: 'Work Sans', sans-serif
  font-size: 16px
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  -webkit-backface-visibility: hidden
  

h1,
.h1
  font-size: 36px

h2,
.h2
  font-size: 30px

h3,
.h3
  font-size: 26px

h4,
.h4
  font-size: 22px

h5,
.h5
  font-size: 20px

h6,
.h6
  font-size: 18px

h1, h2, h3, h4, h5, h6
  padding: 1rem 0
  margin: 0



hr.divider
  height: 2px
  border: 0
  background-color: rgba(169, 180, 194, 0.25)

// Fonts

.font-bold
  font-weight: 700
.font-regular
  font-weight: 400
.font-light
  font-weight: 300

.font-small
  font-size: 14px



.primary-color
  //color: #6B246B
  color: #a646eb
.primary-bgcolor
  background-color: #a646eb

.secondary-color
  color: #39DECB
.secondary-bgcolor
  background-color: #39DECB

.dark-color
  color: #2B303A
.dark-bgcolor
  background-color: #2B303A

.yellow-color,
.warning-color
  color: #FFBE13
.yellow-bgcolor,
.warning-bgcolor
  background-color: #FFBE13

.red-color,
.error-color
  color: #F67173
.red-bgcolor,
.error-bgcolor
  background-color: #F67173

.orange-color
  color: #FF7B24
.orange-bgcolor
  background-color: #FF7B24

  
// UTILS
// TODO: Migrte to hagrid helpers
.block
  display: block

.p-2
  padding: 2em

.p-4-x
  padding-top: 4em 
  padding-bottom: 4em

.p-1-y
  padding: 1em 0

.flex
  display: flex

.bold
  font-weight: bold

.uppercase
  text-transform: uppercase

.justify-content-space-between
  justify-content: space-between

.view-module-title
  padding: 1.5em 1em

.common-label
  padding: .5em 0
  display: block

.align-right
  text-align: right

//Badges
.badge
  display: inline-block
  padding: .25em .4em
  font-size: 75%
  font-weight: 700
  line-height: 1
  text-align: center
  white-space: nowrap
  vertical-align: baseline
  border-radius: .25rem

  &.badge-pill    
    padding-right: .6em
    padding-left: .6em
    border-radius: 10rem
  
  &.badge-success
    color: #fff
    background-color: #28a745

  &.badge-danger
    color: #fff
    background-color: #dc3545
  
  &.badge-warning
    color: #212529
    background-color: #ffc107

  &.badge-light
    color: #212529
    background-color: #f8f9fa
    border: solid 1px black
    
  &.badge-primary
    color: #fff
    background-color: #007bff

  &.badge-secondary
    color: #fff
    background-color: #6c757d

  &.badge-info
    color: #fff
    background-color: #0DCAF0

  &.badge-light-purple
    color: #fff
    background-color: #ad8bd9
  
  &.badge-wao-orange
    color: #fff
    background-color: #e7533a  

  &.badge-dark-blue
    color: #fff
    background-color: #475470

  &.badge-narrow
    padding-right: 4px
    padding-left: 4px

//Buttons

.btn-close
  color: #FFFFFF
  width: 40px 
  height: 40px 
  padding: 0px 

button,
.btn
  box-shadow: none!important
  color: #fff
  font-weight: bold
  font-size: 16px
  border: 0
  outline: none
  border-radius: 5px
  padding: .7em 3em
  transition: all .3s

  &.btn-primary
    background-color: #a646eb

    &:hover
      background-color: rgb(116, 49, 164)

  &.btn-secondary
    background-color: #39DECB
    &:hover
      background-color: rgb(39, 155, 142)

  &.btn-cancel
    background-color: #fff
    border: 1px solid #39DECB
    color: #39DECB
    &:hover
      background-color: #fcfcfc


  &.btn-warning
    background-color: #ffcf4d
    color: #2a1d3b

    &:hover
      background-color: #deb74c

  &.btn-danger,
  &.btn-error
    background-color: #f67173

    &:hover
      background-color: #e15c5e
    
  
  &.btn-warning
    background-color: #ffcf4d
    
  &.btn-transparent
    background-color: transparent
    color: #2a1d3b
    &:hover
      background-color: #8883

  &.btn-warning,
  &.btn-danger,
  &.btn-error
    color: #2a1d3b

  &.btn-block
    width: 100%

.d-flex
  display: flex

// Layout and element

.main-page-view
  display: flex
  height: 100%
  overflow: hidden
  
.main-content-view
  width: 100%
  background: #f6f5f7
  overflow: auto

.breadcrumbs
  padding: 2em 1em

  span
    font-weight: bold
  
  .__separator
    width: 15px
    height: 15px
    position: relative
    display: inline-block
    padding: 0 .5em
    top: 2px

    i
      position: absolute
      transform: rotate(180deg)


.pagination
  ul
    display: flex
    margin: 0
    padding: 0
    list-style: none
    justify-content: flex-end
    align-items: center

  li

    a
      display: block
      width: 30px
      height: 30px
      text-align: center
      line-height: 30px
      width: 30px
      height: 30px
      border-radius: 30px
      border: 1px solid transparent
      padding: 0
      text-align: center
      color: #2b303a
      background-color: transparent
      border: 1px solid red
      border-radius: 30px
      margin: 0 2px
      border: 1px solid transparent
      cursor: pointer

      &[rel="prev"]
        background-color: #893b90
        color: #fff
        width: 20px
        height: 20px
        line-height: 20px
        font-weight: bold
        font-size: 13px
        margin-right: 10px

      &[rel="next"]
        background-color: #893b90
        color: #fff
        width: 20px
        height: 20px
        line-height: 20px
        font-weight: bold
        font-size: 13px
        margin-left: 10px


    &.active
      a
        border-color: #893b90
        color: #893b90
        font-weight: bold


// FORMS
.form-wrap

  .form-group
    padding-bottom: 1.5em

  .form-field
    padding: 0 0 1.5em

  .col-form-label
    display: block
    font-weight: 500

    b
      font-weight: 400
      font-size: 14px
  
  .form-control
    padding: .7em 1em
    border-radius: 5px 
    display: block
    width: 100%
    box-sizing: border-box
    border: 1px solid rgb(226, 229, 237)
    font-size: 14px 

    &:active,
    &:focus
      border: 1px solid #39DECB

  .form-control__error
    padding: .7em 1em
    border-radius: 5px 
    display: block
    width: 100%
    box-sizing: border-box
    border: 1px solid #f21f21
    font-size: 14px 

  .formSuccess
    font-size: 12px
    text-align: right
    color: #39AB4B

  .formError
    font-size: 12px
    text-align: right
    color: red

  .formWarning
    font-size: 12px
    text-align: right
    color: #ee7d40
    font-weight: bold

  .formInfo
    font-size: 12px
    text-align: right
    color: grey

  .form-crud-actions
    text-align: right

.btn-close-content
  text-align: right 
  height: 10px 
  margin-top: 20px
  margin-bottom: 20px

textarea
  font-size: 16px
  color: #000

.box
  background: rgb(255, 255, 255)
  border-radius: 10px
  border: 1px solid rgb(234, 237, 243)
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.04)
  min-height: 35px
  margin: 0 2em 2em 2em
  padding: 1.5em
  position: relative

  &.box-margin
    margin: 1em

  &.box-padding
    padding: 0
  
  &.box-average
    width: 220px
    overflow: hidden
    padding: 1.5em
    padding-bottom: 2.5em

    footer
      background-color: #8757b2
      color: #fff
      text-align: center
      position: absolute
      width: 100%
      font-size: 14px
      padding: .2em 0
      bottom: 0
      left: 0

  &.box-table
    padding: 0

    .cell-data2
      opacity: .5
      font-size: 13px
      display: block

    img
      width: auto
      height: 60px
  
  .box-inner
    padding: 1em


.table-placeholder-item
  // box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15)
  // border-radius: 4px
  // height: 100px
  // width: 100%
  position: relative
  overflow: hidden
  height: 20px

  &:before
    content: ''
    display: block
    position: absolute
    left: -100%
    top: 0
    height: 100%
    width: 100%
    background: linear-gradient(to right, transparent 0%, #f5f5f5 50%, transparent 100%)
    animation: load 1.3s cubic-bezier(0.1, 0.0, 0.2, 1) infinite



@keyframes load
  from 
    left: -150px
  
  to 
    left: 100%
    
.label-asterisk
  font-weight: bold
  color: red
  margin-left: 2px

.label-separator
  color: #ccc
  font-weight: normal
  padding: 0px 4px

.currentContent 
  background-color: #fff
  padding-right: 0px !important
  padding-left: 0px !important
  position: relative
  overflow: auto
  height: 100%
  flex: 0 1 auto
 

  form button.btn-danger 
    margin-left: 10px


  label.col-form-label 
    font-weight: bold


.deal-separator
  width: 81%
  margin: 20px auto


.solution-images 
  border: dashed 2px #ced4da
  border-radius: 5px
  background-color: #F0F0F0
  color: #555
  display: block

  .cloud-icon
    font-size: 42px
    display: block
    margin: 12px auto 12px auto
    color: #909090
    text-align: center

  &.active
    background-image: repeating-linear-gradient(45deg, #FFF, #FFF 10px,  #F6F6F6 10px, #F6F6F6 20px)
    height: 142px
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    
  .arrow-circle-down-icon 
    font-size: 42px
    display: block
    margin: 12px auto 20px auto
    color: #909090

.optionListContainer
  width: 80px
  display: inline-block
  margin: 0 .5em
  
.upload-text 
  display: block
  text-align: center
  color: #646464
  margin-top: 20px


.upload-file-types
  display: block
  text-align: center
  font-style: italic
  font-size: 11px
  color: red
  margin-bottom: 20px


.upload-file-section
  margin-top: 25px


.searchSelectGroup
  margin-bottom: 8px

  
.bold-label
  font-weight: bold


input:disabled
  background: #eee !important
  color: #777 !important

  
.form-control::placeholder
  //color: #555
  color: #9A9FA5 

.tag-input input  
  width: 300px !important

.address-pcode
  padding-left: 0px !important

.meta-checkbox span  
  padding-left: 6px
  font-weight: bold

.form-label
  padding-bottom: 0.5em

.deal-image-sm
  max-width: 80px
  max-height: 60px

.deal-image
  max-width: 100%
  max-height: 150px

.center-text
  text-align: center  

.table-badge
  font-size: 12px
  font-weight: 60000  
  padding: 3px 10px

.clickable
  cursor: pointer

.link
  text-decoration: underline
  cursor: pointer

.date-created
  margin-top: -20px
  
.date-updated
  margin-bottom: 40px

.header-form
  margin-bottom: 30px

.address-divider
  margin-bottom: 11px

.badge-description
  color: #938c9c
  font-size: 14px

.capitalize
  text-transform: capitalize

.form-wrap
  .phone-input
    width: 100%

textarea:disabled 
  background: #eee !important
  color: #777 !important

.status-dot
  font-size: 18px
  margin-right: 3px

.warning-deal
  color: #ffcf4d
  -webkit-text-stroke-width: 1px
  -webkit-text-stroke-color: #cba337

.expired-deal
  color: #f67173
  -webkit-text-stroke-width: 1px
  -webkit-text-stroke-color: #b23e40

.active-deal
  color: #53e7c9
  -webkit-text-stroke-width: 1px
  -webkit-text-stroke-color: #359783

.table-address-icon
  margin-right: 3px

.table-list-address
  font-size: 13px
  color: #000
  &.invalid
    color: #E24E78

.table-list-phone
  font-size: 13px   
  color: #9E98A6

.z2
  z-index: 2

.margin-left
  margin-left: 25px !important

.search-description
  font-size: 12px
  color: #888
  font-style: italic
 
.alt-sub-table
  box-shadow: none
  margin-bottom: 12px
  margin-top: 15px

  .sub-table-head
    background-color: #481E66
    color: #fff

.alt-sub-table::before
  content: ""
  // box-shadow: inset rgba(0, 0, 0, 0.65) 0px -20px 10px -15px, inset rgba(0, 0, 0, 0.55) 0px 20px 10px -15px
  // border: 1px solid #BBB
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  pointer-events: none //So you can click through it

/* Tooltip container */
.tooltip
  position: relative
  display: inline-block
  border-bottom: 1px black

/* Tooltip text */
.tooltip .tooltiptext 
  visibility: hidden
  width: 120px
  background-color: black
  color: #fff
  text-align: center
  padding: 5px 0
  border-radius: 6px
 
  /* Position the tooltip text - see examples below! */
  position: absolute
  z-index: 1

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext 
  visibility: visible

.input-clear
  position: absolute
  background-color: transparent
  right: 4px
  top: 0px
  text-align: center
  padding: 0
  cursor: pointer

.inner-component-view
  display: flex
  flex-flow: column
  // TODO Disabled this until we actually use the header
  // height: calc(100% - 80px) //80px from white header
  height: 100%

.flex-center
  justify-content: center
.column
  flex-direction: column
.column-reverse
  flex-direction: column-reverse
.row
  flex-direction: row
.row-reverse
  flex-direction: row-reverse
.nowrap
  white-space: nowrap
.g6
  gap: 6px
.g4
  gap: 4px

.env-overlay
  text-align: center
  z-index: 1000000
  position: fixed
  left: 50%
  top: 1%
  transform: translate(-50%)
  padding: 6px
  color: rgba(255, 0, 0, 0.6)
  background: rgba(200, 200, 200, 0.1)  
  border-radius: 6px
  font-size: 10px
.overlay
  background: rgba(242, 242, 242, 0.79)
  width: 100%
  height: 100%
  z-index: 1000000
  position: fixed
  transition: all 0.5s ease-out
  div
    color: white
    width: 10em
    height: 2em
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    font-size: 14px
    font-weight: bold
    display: flex
    justify-content: center
    span
      border-radius: 11px
      background: #05604d
      align-items: center
      padding: 7px 21px
      letter-spacing: -0.31px
      white-space: nowrap
      display: flex
      align-items: center
      span
        background: none
        padding: 0

.sticky
  position: -webkit-sticky //Safari 
  position: sticky
  top: 0


.contact-section
  margin-top: -6px
  margin-bottom: 20px
  margin: 20px
  background: rgba(0, 166, 153, 0.1)
  border-radius: 6px
  padding: 20px
  h3
    font-size: 18px
    text-align: center
    font-weight: normal
    letter-spacing: -0.6px
    //padding: 20px 40px
  .contact-content
    //margin: 0px 70px 20px
    button
      margin: 10px 0px 0px
      padding: 14px 0px
      font-size: 18px !important 
      font-weight: normal
      background: "rgb(0, 166, 153) !important"
      svg
        margin-right: 8px

.tabs
  // padding: 0 1em

  button
    border: 1px solid #dcdcdc
    background: #f1f1f1
    color: #969696
    font-weight: normal
    border-radius: 5px 5px 0 0
    margin-right: .5em

    &.__active
      background-color: #ffffff
      color: #893b90
      border-bottom-color: #893b90
.wrap-box
  padding: 1.5em

.react-datepicker__input-container
  input
    padding: .7em 1em
    border: 1px solid #ccc
    border-radius: 5px
    font-size: 14px
    width: 100%
    box-sizing: border-box