.group-view
  background-color: #fff
  text-align: left
  padding: 20px


.group-date
  padding-top: 14px
  padding-bottom: 14px

.react-tagsinput
  font-size: 14px


.react-tagsinput-tag
  background-color: #007bff !important
  border: 1px solid #007bff !important
  color: #fff !important
  border-radius: 10px !important


.react-tagsinput-remove
  cursor: pointer !important
  font-weight: bold !important


a.react-tagsinput-remove
  color: #fff !important
  cursor: pointer !important
  font-weight: bold !important


.react-tagsinput--focused
  border-color: #ced4da !important


.margin-bot
  margin-bottom: 20px


div#multiselectContainerReact div
  padding-left: 10px


.optionListContainer
  padding-left: 0px !important

.delete-filter
  cursor: pointer
  margin-left: 6px


.applied-filter
  margin-bottom: 4px


.applied-filter-tag
  border-radius: 4px
  border: 1px solid #A646EB
  background-color: #E9D0FB
  padding: 3px 4px
  color: #A646EB
  font-size: 14px


.filter-connector
  font-style: italic
  font-size: 14px
  margin: 4px 0px 4px 0px