.user-view 
  background-color: #fff   
  text-align: left
  padding: 20px
  overflow-wrap: anywhere


.user-view input.short 
  width: 173px
  margin-right: 2px


.lat-label 
  justify-content: left !important
  width: 173px


.long-label 
  justify-content: left !important
  width: 173px


.lat-field   
  margin-bottom: 20px


.long-field   
  margin-bottom: 20px


.user-name 
  padding-top: 14px


.user-title 
  color: rgb(97, 97, 97)
  font-size: 16px
  font-weight: bold
  margin: 10px 0 8px 0


.user-view .btn-danger 
  color: #fff

.delete-section

  .delete-description
    margin-bottom: 30px
  .delete-buttons
    text-align: right
  .btn-button
    margin-left: 10px

