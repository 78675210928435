.order-address-icon
  margin-right: 3px

.order-list-address
  font-size: 13px
  color: #000
  &.invalid
    color: #E24E78

.order-list-phone
  font-size: 13px   
  color: #9E98A6

.status-container
  display: flex
  flex-direction: row
  align-items: flex-start
  gap: 10px
  width: 85%

  button
    margin-top: 16px

  .status-section
    display: flex
    flex-direction: column 
    width: 20%

    .status-item
      flex-grow: 2

    .reasons-dropdown
      margin-top: 6px 
  .status-section-title
    padding: 20px

.subtable
  padding-top: 0px 
  margin-top: 0px !important
  margin-bottom: 0px !important