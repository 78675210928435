
.tablecell-inner
  position: relative
  display: inline-block
  cursor: pointer

  i
    position: absolute
    transition: all .3s        
    visibility: hidden
    margin-left: .5em
      
  &.sort-cell
    border-bottom: 1px solid


  &.sorting-active
    i
      visibility: visible

  &.sorting-active-desc
    i
      transform: rotate(0deg)

  &.sorting-active-asc
    i
      transform: rotate(180deg)

.text-row
  .description
    margin-top: 6px
    &.sub
      color: #938c9c
      font-size: 13px

.toggler
  display: flex
  justify-content: center
  align-items: center
  align-content: center

.alertBorderRow
  td:first-child
    border-left: 3px solid #dc3545 !important
  td
    border-top: 3px solid #dc3545 !important
  td:last-child
    border-right: 3px solid #dc3545 !important
.subAlertBorderRow
  td:first-child
    border-left: 3px solid #dc3545 !important
  td
    border-bottom: 3px solid #dc3545 !important
  td:last-child
    border-right: 3px solid #dc3545 !important
.subAlertBorderRowSides
  td:first-child
    border-left: 3px solid #dc3545 !important
  td:last-child
    border-right: 3px solid #dc3545 !important