.order-view
  background-color: #fff  
  text-align: left
  padding: 20px

  .socio-buys-checkbox
    label
      .label
        margin-left: 10px
      .description
        margin-left: 10px
        font-size: 12px
        color: #888
        font-style: italic
        
  .sm-base-price
    font-size: 12px
    color: #888
    font-style: italic


.order-date
  padding-top: 14px
  padding-bottom: 14px


.react-tagsinput-tag
  background-color: #007bff !important
  border: 1px solid #007bff !important
  color: #fff !important
  border-radius: 10px !important


.react-tagsinput-remove
  cursor: pointer !important
  font-weight: bold !important

.link-change-field
  color: #206e65
  text-decoration: underline
  padding: 1em 0
  display: inline-block
  cursor: pointer
  position: relative
  top: -25px
  font-size: 14px


a.react-tagsinput-remove
  color: #fff !important
  cursor: pointer !important
  font-weight: bold !important


.react-tagsinput--focused
  border-color: #ced4da !important


.margin-bot
  margin-bottom: 20px


div#multiselectContainerReact div
  padding-left: 10px


.optionListContainer
  padding-left: 0px !important


.order-view .order-checkbox
  margin-top: 8px
  font-weight: bold


.order-view .order-checkbox input
  width: 26px


.order-checkbox
  font-size: 14px



.order-title
  color: rgb(97, 97, 97)
  font-size: 16px
  font-weight: bold
  margin: 10px 0 8px 0


.order-info
  background: rgb(242, 242, 242)
  border-radius: 4px
  padding: 16px
  margin: 6px 0 6px 0


.tendero-info-name
  color: rgb(97, 97, 97)
  font-size: 16px


.product-prices
  white-space: nowrap
  display: inline


.product-total-label
  color: rgb(97, 97, 97)
  font-size: 20px
  font-weight: bold


.product-total-price
  color: rgb(177, 35, 52)
  font-size: 20px
  font-weight: bold
  margin-left: 10px

.product-commission-label
  color: rgb(97, 97, 97)
  font-size: 20px


.product-commission-price
  color: rgb(62, 156, 33)
  font-size: 20px
  margin-left: 10px



.product-out-of-stock
   color: rgb(177, 35, 52)
   font-size: 14px
   font-style: italic
   font-weight: bold

.totals-section
  padding-top: 20px
  display: flex  
  justify-content: flex-end
  div
    display: flex
    flex-direction: column
    align-items: flex-end

.totals-labels
  margin-right: 20px

.total-row
  padding-top: 16px
  text-align: right

.col-form-label
  color: rgb(33, 43, 54)


.product-details
  padding: 10px
  max-height: 230px


.product-name
  color: rgb(36, 44, 62)
  font-size: 14px
  font-weight: bold


.product-details-info
  height: 100%
  /* max-height: 150px*/


div.product-image
  justify-content: center
  display: flex
  align-items: center
  object-fit: scale-down
  width: 100%
  height: 100%
  max-height: 168px

.product-image-sm
  justify-content: center
  display: flex
  align-items: center
  object-fit: scale-down
  width: 100%
  height: 100%
  max-height: 100px !important
  max-width: 100px !important


.order-view div.product-image img
  object-fit: scale-down
  width: 100%
  height: 100%


.product-content
  padding: 0px 
  min-width: 175px
  

.submit-div
  margin-top: 20px


.qty-inc-btn
  cursor: pointer
  width: 24px
  height: 24px
  border-radius: 0 4px 4px 0
  border: 1px solid rgb(216, 216, 216)
  box-shadow: inset 0px 1px 2px 0px rgba(102, 113, 123, 0.21)

.qty-inc-btn:disabled
  cursor: not-allowed


.qty-dec-btn
  cursor: pointer
  width: 24px
  height: 24px
  border-radius: 4px 0 0 4px
  border: 1px solid rgb(216, 216, 216)
  box-shadow: inset 0px 1px 2px 0px rgba(102, 113, 123, 0.21)

.qty-dec-btn:disabled
  cursor: not-allowed

.qty-input
  text-align: center
  width: 40px
  height: 20px
  border-style: solid
  border-color:  rgb(216, 216, 216)
  border-width: 1px 0 1px 0
  position: relative

.qty-input:disabled
  cursor: not-allowed

.arrow-up
  width: 0
  height: 0
  border-left: 5px solid transparent
  border-right: 5px solid transparent
  border-bottom: 5px solid rgb(166, 70, 235)


.arrow-down
  width: 0
  height: 0
  border-left: 5px solid transparent
  border-right: 5px solid transparent
  border-top: 5px solid rgb(166, 70, 235)


.product-per-unit
  color: rgb(42, 29, 59)
  font-size: 15px
  font-weight: bold


.product-no-discount
  text-decoration: line-through
  color: rgb(226, 78, 120)
  font-size: 13px
  margin-right: 4px


.product-discount
  color: rgb(42, 29, 59)
  font-weight: bold
  font-size: 15px
  padding-right: 6px


.product-discount-percent
  font-size: 13px
  color: rgb(137, 59, 144) 


.product-commission
  font-size: 15px
  color: rgb(62, 156, 33) !important
  padding-right: 6px

.commission-percentage
  color: rgb(62, 156, 33) !important

.product-quantity-section
  text-align: center
  margin-top: 10px


.product-quantity
  font-size: 13px
  color: rgb(42, 29, 59)


.product-savings
  font-weight: lighter
  color: darkred


.description-label
  font-size: 10px
  font-style: italic


.order-info-tooltip::before
  width: 230px


.deal-icons
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  min-width: 16px
  padding: 2px


.red
  background-color: red


.blue
  background-color: blue


.green
  background-color: green


.yellow
  background-color: yellow


.order-info-icon
  cursor: pointer
  margin-right: 3px
  margin-bottom: 4px

.close-price
  left: -37px
  position: relative
  font-size: 10px


.edit-price
  font-size: 13px
  height: 24px
  width: 100px !important  
  margin-top: 3px
  margin-right: 28px
  display: inline !important
  padding-right: 4px !important
  padding-left: 4px !important

.edit-commission
  font-size: 13px
  height: 24px
  width: 70px !important  
  margin-top: 3px
  margin-right: 28px
  display: inline !important
  padding-right: 4px !important
  padding-left: 4px !important

.editable-field
  display: flex
  align-items: flex-end

.edit-label
  display: inline
  font-style: italic
  font-size: 11px
  padding: 6px 2px 0 0


.editing-label
  display: block
  font-style: italic
  font-size: 11px
  padding: 6px 2px 0 0
  
.quantity-btn
  display: flex
  justify-content: center

.quantity-btn button  
  padding: 6px

.products-hr th
  padding: 12px

.products-table-cell
  border: 1px solid rgb(204, 204, 204)
  tr, th
    border-top: 1px solid rgb(204, 204, 204)
    border-bottom: 1px solid rgb(204, 204, 204)
  
.expected-delivery-date input
  width: 214px

.deal-error-row
  background-color: #eee !important

.search-description
  font-size: 12px
  color: #888
  font-style: italic

.copy-to-clipboard-link
  display: flex
  align-items: center
  margin-top: 5px

  input
    margin-right: 5px
    width: 100%
    border: 1px solid #cccccc
    border-radius: 4px 
    padding: 5px
  
  .copy-to-clipboard-link__button
    border: 1px solid #cccccc
    border-radius: 4px
    background: none
    padding: 5px
    cursor: pointer