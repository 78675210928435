.google-search-wrapper
  position: relative
  display: flex  
  align-items: center
  input
    width: 100%
    display: block
    padding: .7em 12px
    border-radius: 12px
    box-sizing: border-box
    border: 1px solid rgba(4, 4, 21, 0.1)
    font-size: 14px
    &.clearable
      padding-right: 26px
  svg
    top: 14px
    right: 10px

.google-listing-wrapper
  position: relative
  z-index: 3

  ul
    position: absolute
    padding: 0
    list-style: none
    background: #f6f6f6
    font-size: 12px
    margin: 0
    border-radius: 5px
    width: 100%
    border: 1px solid rgb(235, 235, 235)
    display: none

    &.__show
      display: block


    li
      list-style: none
      padding: .7em 1em
      cursor: pointer

      &:hover
        background: rgba(255, 255, 255, .5)

      &.select-address-label
        border-bottom: 1px solid #ebebeb
        font-weight: bold

      &.find-on-map
        color: #e54a38
        border-top: 1px solid #ebebeb

        figure
          display: inline-block
          padding: 0
          margin: 0
          margin-right: .5em

.google-map-wrapper
  position: relative
  z-index: 2

.google-map-wrapper
  .__data
    font-size: 12px
    font-family: 'Work Sans', sans-serif
    margin-top: 10px

    span
      font-weight: bold
      
    p
      margin: 0

  .__actions
    text-align: right
    padding: 1em 0

    button
      background: #e54a38
      height: 28px
      font-size: 12px

.google-map-container
  padding: 1em
  margin: 1em 0
  background: rgb(246, 246, 246)
  border-radius: 6px


.google-map-element
  height: 300px

.address_states
  //position: absolute

  .address_states__inner
    padding: .5em 0

  figure
    display: inline-block
    padding: 0
    margin: 0
    margin-right: .5em

  .__verified,
  .__unverified
    display: block
    font-size: 12px
    display: none

    &.__active
      display: block

  .__verified
    color: #068f20

  .__unverified
    color: #f4000d
    span
      margin-bottom: 10px

  .__unverified_cta
    color: #f4000d
    font-size: 12px
    text-decoration: underline
    padding: 0 0 1em 0
    cursor: pointer

.relative-container
  position: relative
  margin-top: 18px
  svg
    position: absolute
    top: calc(50% - 36px)
    left: calc(50% - 18px)
    
.divider
  position: relative
  z-index: 1
  border: 0px
  height: 1px
  background-color: #e4e4e8
  margin: 10px 0px

.loading
  color: #e54a38
  font-size: 12px
  margin: 10px