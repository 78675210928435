.section-pagination
  padding: 1em
  flex: 0 1 auto

.pagination-options

  input
    margin: 0 .5em
    width: 40px
    text-align: center


.pagination-nav
  justify-content: flex-end
  display: flex

  button
    width: 30px
    height: 30px
    border-radius: 30px
    border: 1px solid transparent
    padding: 0
    text-align: center
    color: #2b303a
    background-color: transparent

    &.__active
      border-color: #893b90


.optionListContainer
  width: 80px
  display: inline-block
  margin: 0 .5em