.overlay
  background: rgba(242, 242, 242, 0.79)
  width: 100%
  height: 100%
  z-index: 1000000
  position: fixed
  transition: all 0.5s ease-out
  div
    color: white
    width: 10em
    height: 2em
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    font-size: 14px
    font-weight: bold
    display: flex
    justify-content: center
    span
      border-radius: 11px
      background: #05604d
      align-items: center
      padding: 7px 21px
      letter-spacing: -0.31px
      white-space: nowrap
      display: flex
      align-items: center
      span
        background: none
        padding: 0