.wrap-box
    padding: 1.5em

h4
    padding: .7rem 0

.header-views
  display: flex
  flex: 0 1 auto
  .col-left
    width: 100%

  .col-right
    flex: 1

  .__actions
    display: flex
    justify-content: flex-end
    white-space: nowrap
    flex-wrap: wrap
    gap: 16px

.filters-button
  background-color: #FFFFFF
  border-radius: 4px
  border: 1px solid rgb(226, 229, 237)
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08)
  width: 100px
  display: flex
  vertical-align: middle
  justify-content: center
  align-items: center
  padding: 8px 16px

  .text
    width: 100%
    font-weight: 400
    font-size: 14px

  .button
    display: flex
    flex-direction: column
    justify-content: center
    align-items: flex-end
    
    //color: #893b90 set by element
  
  &.selected
    .text
      color: #893b90


.module-listed
  color: #8E939C
  font-family: 'Work Sans'
  font-size: 14px
  font-weight: 500