.header-fraud
  display: flex
  align-items: center

  .header-fraud__img
    margin: auto 10px  auto 0
    img
      width: 100px

.table-fraud
  display: flex
  flex-flow: column
  height: 350px

.risk
  display: flex
  align-items: center

  .risk__icon
    margin-right: 8px
  
  .risk__status
    font-size: 16px

