.wrap-range-price
  display: flex
  gap: 24px
  
  input.__input
    border-color: #ccc
    margin: 0 .25em
  
  .form-control
    padding: .7em 1em
    border-radius: 5px 
    display: block
    width: 100%
    box-sizing: border-box
    border: 1px solid rgb(226, 229, 237)
    font-size: 14px 

.common-label
  color: #666