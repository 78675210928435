.main-sidebar
  background: #310f49
  padding-left: 40px
  flex: 0 0 260px
  transition: all .3s

  .main-sidebar__header
    text-align: center
    padding: 16px 0px
    display: flex
    justify-content: space-evenly
    flex-wrap: wrap
    
    a
      svg
        height: 20px
    
    .title
      display: flex
      gap: 16px

    .sidebar-toggle
      span
        cursor: pointer
        display: inline-block
        //width: 40px
        overflow: hidden
        transition: all .5s
        background: rgb(48, 24, 66)
        border-radius: 15px
        padding: 4px
        display: flex
    
    .break
      flex-basis: 0%
      height: 0

  &.__main-sidebar-toggled
    flex: 0 0 60px
    padding-left: 0px

    .main-sidebar__header
      .logo
        display: none

      .break
        flex-basis: 100%

    .sidebar-nav
      b
        display: none

    .sidebar-toggle
      margin-top: 10px
      span
        transform: rotate(180deg)


    .sidebar-nav
      span
        display: none

    .main-sidebar__footer
      nav.log-item
        width: 100%
        a
          width: 100%
      .version-label,
      .nav-country,
      div.user
        display: none


  &.__main-sidebar-twilio
    padding-left: 0

    .logo
      display: none

    .sidebar-nav
      b
        display: none
  
  .main-sidebar__container
    background: #230a36
    height: 100%
    display: flex
    flex-direction: column

  .sidebar-content
    overflow: auto
    height: 100%

  .sidebar-nav

    b
      color: #fff
      padding: 1em
      font-size: 14px
      font-weight: 700
      display: block
      text-transform: uppercase

    a
      display: flex
      color: #fff
      padding: 1.1em 0.5em 1.1em 1em
      font-size: 14px
      cursor: pointer
      text-decoration: none
      transition: all .5s

      &.__current
        background: rgb(72, 31, 103)

      
      i
        margin-left: .5em
        margin-right: 1em

      &:hover
        background: rgb(72, 31, 103)

.main-sidebar__footer
  background-color: #0000008c
  // height: 40px
  color: #fff
  font-size: 12px

  .version-label
    display: flex
    flex-direction: column
    padding: .5em
    gap: 2px
  div.user
    display: flex
    padding: 10px 16px
    i
      padding-right: 6px
  
  nav.log-item
    
    a
      height: 40px
      width: 50px
      display: inline-block
      text-align: center
      line-height: 40px
      background-color: rgba(0,0,0, .5)

  .d-flex
    display: flex
    justify-content: space-between
    align-items: center

    button
      background: transparent
      width: auto
      padding: .7em 1em

.country-selector
  color: white
  background: rgb(48, 24, 66)
  border-radius: 4px
  height: 22px
  border: none
  padding-left: 6px
  padding-right: 28px
  appearance: none
  -moz-appearance: none // Firefox
  -webkit-appearance: none // Safari and Chrome

  &.enabled
    background-image: linear-gradient(45deg, transparent 50%, white 50%), linear-gradient(135deg, white 50%, transparent 50%)
    background-position: calc(100% - 12px) calc(1em + -7px), calc(100% - 8px) calc(1em + -7px)
    background-size: 4px 4px, 4px 4px
    background-repeat: no-repeat

.country-container
  display: inline
  vertical-align: middle
