.range-price
  color: #8d8d8d
  font-size: 14px
  margin: 0

.preview-price
  display: flex
  justify-content: space-between

.filter-wrapper
  position: relative

.filter-input-icon
  position: absolute
  background-color: transparent
  right: 16px
  top: 8px
  width: 40px
  text-align: center
  padding: 0
  height: 36px  

.reset-filters-button
  color: #893b90
  text-decoration: underline
  cursor: pointer

.date-input + button::after
  background-color: transparent
  color: #893b90
  font-size: 20px
  right: 47px
  position: relative

.common-label
  color: #666