.tabs
    padding: 0 1em

    button
        border: 1px solid #dcdcdc
        background: #f1f1f1
        color: #969696
        font-weight: normal
        border-radius: 5px 5px 0 0
        margin-right: .5em

        &.__active
            background-color: #ffffff
            color: #893b90
            border-bottom-color: #893b90
.wrap-box
    padding: 1.5em