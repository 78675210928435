.order-container
    display: flex
    gap: 16px

.order-input-container
    position: relative
    input
        width: 35px
        padding-left: 5px
    svg
        position: absolute
        top: 4px
        right: 4px

.ok-button
    color: white
    background: black
    font-size: 11px
    margin-left: 3px
    width: 22px
    height: 22px
    cursor: pointer
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    border-radius: 5px

.main-container
    display: flex
    flex-direction: row
    align-items: stretch
    border-radius: 5px

.edit-color
    color: #000000
    text-decoration: #fff
    
    &:hover
        
     color: #2600ff
    