.blast-view
  background-color: #fff
  text-align: left
  padding: 20px

.optionListContainer
  padding-left: 0px !important

.blast-deals
  margin-top: 60px

.blast-deals-empty
  margin-top: 140px

.limit-blast
  display: flex
  align-items: center
  gap: 10px
  
.blast-recurrency__repeatEach
  display: flex
  align-items: center
  gap: 10px
  margin-bottom: 20px
  // .blast-recurrency__repeatEach__label
  
  .blast-recurrency__repeatEach__quantity
    width: 48px
    height: 15px
    padding: 10px

  .blast-recurrency__repeatEach__select
    width: 120px

.blast-recurrency__repeatEndAt
  display: flex
  align-items: center
  gap: 85px
  margin-bottom: 20px
  // .blast-recurrency__repeatEndAt__label
  
  .blast-recurrency__repeatEndAt__date

    input
      border-radius: 0px !important
      width: 121px !important
      height: 25px !important

.blast-recurrency__repeatEndNever
  margin-bottom: 20px

.blast-recurrency__repeatAfter
  display: flex
  align-items: center
  gap: 10px
  margin-bottom: 20px
  // .blast-recurrency__repeatAfter__label
  
  .blast-recurrency__repeatAfter__quantity
    width: 48px

.blast-btn-container
  display: flex
  justify-content: flex-end
  gap: 10px
  button
    padding: 10px 20px !important

.usersCount
  background: #f2f2f2
  display: flex
  justify-content: center
  align-items: center
  padding: 10px
  margin: 30px 0

.time-inputs
  div
    position: relative
    label
      position: absolute
      z-index: 1
      top: 14px
      left: 14px

