.container
  width: 100%
  margin: 0px
  padding: 0px
  max-height: 100%
  min-height: 100%
  display: flex
  flex-direction: column
  align-items: stretch
  background: #f6f5f7

  .content
    flex: 1
    margin-top: 54px
    padding: 20px
    display: flex
    flex-direction: column
    align-items: stretch
    justify-content: space-between

    .container_form
      padding: 20px
      margin: 20px auto
      max-width: 400px
      //height: 80px
      background-color: #fff
      box-shadow: 0px 1px 0px 0px rgb(237, 237, 237), 0px 2px 5px 0px rgba(0, 0, 0, 0.07)

      h4
        text-align: center

      .input
        margin-top: 15px

      .container_login_button
        margin-top: 50px

      .container_forgot_password
        margin-top: 20px
