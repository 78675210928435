.deal-view 
  background-color: #fff
  text-align: left
  padding: 20px
  

  .date-group
    position: relative
    z-index: 2

.deal-name
  margin-top: 14px

.country-input
  display: none !important


.price-label
  justify-content: left !important

.col-form-label
  font-weight: bold

.col-form-label .discount-label
  justify-content: left !important
  display: inline


.deal-view .deal-checkbox
  margin-top: 20px


.deal-discount-percent
  font-size: 12px
  font-style: italic
  color: #606060
  font-weight: bold
  margin-left: 6px
  vertical-align: middle


.media-file-size
 font-style: italic
 font-size: 11px
 color: #555
 display: block

.description-bulk
  font-size: 14px
  font-weight: 600
  color: #555
  margin: 20px 0


.media-filename-container
  text-align: center

.media-attr
  margin-top: 10px
  label
    span
      padding-left: 6px
  span.media-filename
    margin: 0 0 6px 5px
    padding: 0px
    a      
      color: #482066 !important
      font-style: italic !important
      text-decoration: underline
.media-priority
  width: 160px !important

.container-use-image
  display: flex
  flex-direction: column
  label
    font-size: 10px
    display: flex
    input[type="checkbox"]
      cursor: pointer


.add-media-btn
  padding: 7px
  margin-top: 6px

.deal-file
  margin-bottom: 20px
  label
    margin-bottom: 20px
  .media-panel
    background: rgb(249, 249, 249)
    border-radius: 4px
    border: 1px solid rgb(238, 238, 238)
    padding: 10px
    &.priority      
      .media-attr
        margin: 0px
        display: flex
        align-items: center
        input
          margin-left: 6px

    .media-container
      border: solid 1px rgb(220, 220, 220)
      border-radius: 4px
      width: 160px !important
      height: 160px !important
      padding: 3px
      margin: 10px 0px 4px
      display: flex
      justify-content: center
      align-content: center
      align-items: center

    .trash-icon 
      cursor: pointer
      margin: 0 4px 0 8px !important

    .deal-image
      max-width: 160px
      max-height: 160px

.container-content
  max-width: 250px

select.deal-price-currency
  background-color: white
  border-radius: 5px 0px 0px 5px !important
  border-right-width: 0px !important
  padding: 8px 0px 9px 5px !important
  margin: 0px

select.deal-price-currency:disabled
  color: black
  appearance: none
  padding: 9px 0px 10px 10px !important
  
select.deal-discount-currency
  @extend select, .deal-price-currency

.deal-price
  border-radius: 0px 5px 5px 0px !important

.deal-discount
  @extend .deal-price
  
.price-div
  display: inline

.discount-container
  position: relative
  &.discount
    span
      position: absolute
      right: 10px
      top: 10px
    input
      padding-right: 30px
  &.amount
    span
      position: absolute
      left: 10px
      top: 10px
    input
      padding-left: 30px

.installmentPayment-content
  position: relative
  overflow: hidden
  transition: all 2s ease-in-out
  -webkit-transition: all 0.5s ease-in-out // Chrome & Safari 
  -moz-transition: all 0.5s ease-in-out // Firefox 
  -o-transition: all 0.5s ease-in-out // Opera 


.installmentPayment-content-show
  height: 320px

.installmentPayment-content-hidden
  height: 0


.installmentPayment
  background-color: rgba(242, 242, 242, 1)
  padding: 15px
  color: #7F7F7F
  transition: all 0.5s 
  position: absolute
  top: 0
  left: 0
  width: 92%
  height: auto
  transition: all 2s ease-in-out
  -webkit-transition: all 0.5s ease-in-out // Chrome & Safari 
  -moz-transition: all 0.5s ease-in-out // Firefox 
  -o-transition: all 0.5s ease-in-out // Opera 


.installmentPayment-show
  transform: translateY(0)
  -webkit-transform: translateY(0) // Chrome & Safari 
  -o-transform: translateY(0) // Opera 
  -moz-transform: translateY(0) // Firefox 

.installmentPayment-hidden
  transform: translateY(-320px)
  -webkit-transform: translateY(-320px) // Chrome & Safari 
  -o-transform: translateY(-320px) // Opera 
  -moz-transform: translateY(-320px) // Firefox 

.installmentPayment-input
  display: flex
  padding: 10px
  padding-right: 85px
  justify-content: space-between
  align-items: center
  position: relative
  border-width: 1px
  border-style: solid
  border-color: rgba(215, 215, 215, 1)
  border-left: 0px
  border-top: 0px
  border-right: 0px
  border-radius: 0px
  border-bottom-right-radius: 0px
  border-bottom-left-radius: 0px

  input[type="radio"]
    margin-right: 10px

.installmentPayment-input__header
  background-color: #D7D7D7
  padding-right: 10px
  span:nth-child(1)
    padding-left: 20px


.installmentPayment-input__label__div
  display: flex
  flex-direction: column
  margin-bottom: 10px

  input[type="checkbox"]
    margin-right: 5px

.installmentPayment-input__copy
  font-size: 13px
  color: #7F7F7F
  margin-left: 20px
