.wrap-search
  position: relative

  input.__input
    border-color: #ccc
    border-radius: 20px

    

button.button-search
  position: absolute
  background-color: transparent
  right: 1px
  top: 1px
  width: 40px
  text-align: center
  padding: 0
  height: 36px