header.header-guideline
    background: #fafafa
    padding: 2em

    h1,
    p
        text-align: center

.section-component
    padding: 2em 1em
    

.heaader-section-component
    padding: 2em 1em
    margin: 2em 0
    border-bottom: 2px solid rgba(169, 180, 194, 0.25)


.color-preview
    height: 60px
    position: relative
    overflow: hidden
    border-radius: 5px

    div
        position: absolute
        width: 50px
        height: 100%

    .variation-1
        right: 100px
        opacity: .9

    .variation-2
        right: 50px
        opacity: .5

    .variation-3
        right: 0
        opacity: .2

.demo-font-weights
    span
        padding: 1em 2em 1em 0
        display: inline-block

.demo-parent-alert-spacing
    padding: .5em 0
