.dialog
  padding-top: 20px !important
.actions
  padding: 24px !important
.title-section 
  .title
    font-size: 20px
    font-weight: bold
    h2
      padding: 0px
  .icon
    margin-left: 40px
    position: relative
    top: -15px
    left: 17px
    cursor: pointer